require('./bootstrap');
import $ from 'jquery';
window.$ = window.jQuery = $;

import Swal from 'sweetalert2';
window.Swal = Swal;

// for ex: this lets us call var swalLoading from any page that has app.js script (all pages that extend \layouts\app.blade.php)
window.swalLoading = Swal.mixin({
    allowOutsideClick: false,
    showConfirmButton: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    didOpen: () => {
        Swal.showLoading();
    },
});

window.dataTablesDefaultColumns = [
    {
        targets: '.render-image',
        render: function (data, type, row, meta) {
            if(type === 'display'){
                return `<a href="${data}" target="_blank"><img src="${data}" width='35'></a>`;
            }
            return data;
        }
    },
    {
        targets: '.dt-no-search',
        searchable: false,
    },
    {
        // columns with this class will show up in search pane
        targets: '.searchPanes',
        searchPanes: {
            orthogonal: 'sp',
            show: true,
        },
        render: function (data, type, row, meta) {
            // this function is so that searchpanes will not display the html links, but just their text
            var res = "";
            if (type === "sp") {
                res = $('<div/>').html(data).contents().text();
                // make case insensitive
                res = res.toUpperCase();
            } else res = data;
            return res;
        }
    },
    {
        // all other columns won't show in search pane
        targets: '_all',
        searchPanes: {
            show: false
        },
    },
];

window.swalConfirmWithInput = Swal.mixin({
    showCancelButton: true,
    confirmButtonText: "Confirm",
    confirmButtonColor: '#d33',
    icon: 'warning',
    allowEnterKey: false,
    input: 'text',
});

window.showImagePreview = function(input, preview) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
            $(preview).attr('src', e.target.result);
            $(preview).show();
        }
        reader.readAsDataURL(input.files[0]);
    }
}
